import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link, StaticQuery, graphql } from 'gatsby';

import {
	MenuIcon,
	PhoneIcon,
	LogoTextIcon,
	LogoImageIcon
} from './icons';
import Footer from './footer';
import Navigation from './navigation';
import { AnimatedLink } from './utils';
import ContextMenu from './context-menu';

import styles from './layout.module.scss';

// import global styles for layout
import '../styles/main.scss';

//
// LAYOUT
//
const Layout = ({ children, isRoot }) => (
	<div 
		className={`${styles.wrapper} ${isRoot ? styles.wrapperIsRoot : ''}`}>
		<Phone />
		<TextLogo />
		<ImageLogo
			icon={
				isRoot
					? LogoImageIcon
					: LogoTextIcon
			} />
		<Menu />
		<Contact />
		<main 
			className={styles.main}>
			{children}
		</main>
    	{
    		isRoot && (
    			<Footer/>
    		)
    	}
    	<ContextMenu />
  	</div>
);

Layout.defaultProps = {
	logoText: 'Armin Ribis',
	isRoot: false
};

Layout.propTypes = {
	logoText: PropTypes.string.isRequired,
	isRoot: PropTypes.bool.isRequired
};

export default Layout;

//
// PHONE
//
const Phone = () => (
  <StaticQuery
    query={phoneQuery}
    render={phoneRender}
  />
);

const phoneRender = (data) => {
	const {
		markdownRemark: {
			frontmatter: {
				number
			}
		}
	} = data;

	return (
		<div 
			className={styles.phone}>
			<a 
				href={`tel:${number}`} 
				className={styles.phoneLink} >
				<PhoneIcon 
					className={styles.phoneIcon} />
			</a>
		</div>
	);
};

const phoneQuery = graphql`
	query layoutPhoneQuery {
		markdownRemark (
			frontmatter: { 
				name: { 
					eq: "phone"
				}
			}) {
			frontmatter {
				number
			}
		}
	}
`;

//
// TEXT LOGO
//
const TextLogo = ({ label }) => (
	<div className={styles.textLogo}>
		{label}
	</div>
);

TextLogo.defaultProps = {
	label: 'Armin Ribis'
};

TextLogo.propTypes = {
	label: PropTypes.string.isRequired
};

//
// IMAGE LOGO
//
const ImageLogo = ({ icon: Icon }) => (
	<div className={styles.imageLogo}>
		<Link
			to='/'
			className={styles.imageLogoLink}>
			<Icon
				className={styles.imageLogoSvg} />
		</Link>
	</div>
);

ImageLogo.defaultProps = {
	icon: LogoImageIcon
};

ImageLogo.propTypes = {
	icon: PropTypes.func.isRequired
};

//
// CONTACT
//
const Contact = ({ label }) => (
	<div className={styles.contact}>
		<AnimatedLink label={label} path='/contact/' />
	</div>
);

Contact.defaultProps = {
	label: 'Contact'
};

Contact.propTypes = {
	label: PropTypes.string.isRequired
};

//
// MENU
//
class Menu extends Component {

	state = {
		isOpen: false
	}

	toggle = (event) => {
		event.preventDefault();

		this.setState(
			(state) => ({
				isOpen: !state.isOpen
			})
		);
	}

	close = (event) => {
		event.preventDefault();
		
		this.setState({
			isOpen: false
		});
	}

	componentWillReceiveProps () {
		// close menu automatically on rerenders
		// if it's still open (f.e. page changes) 
		this.setState({
			isOpen: false
		});
	}

	render() {
		return (
			<nav className={styles.nav}>
				<a
					onClick={this.toggle}
					href={
						!this.state.isOpen
							? '#menu' 
							: ''
					}
					title={this.props.label}
					className={styles.menuToggle}>
					<MenuIcon
						className={styles.menuSvg}
						isActive={this.state.isOpen} />
				</a>
				<div
					id="menu"
					role="button"
					tabIndex="-1"
					onClick={this.close}
					className={styles.menuWrapper}>
					<Navigation
						isOpen={this.state.isOpen}/>
				</div>
			</nav>
		);
	}
}

Menu.defaultProps = {
	label: 'Menu'
};

Menu.propTypes = {
	label: PropTypes.string.isRequired
};
import posed from 'react-pose';
import PropTypes from 'prop-types';
import React, { forwardRef } from 'react';
import { Link, StaticQuery, graphql } from 'gatsby';

import styles from './navigation.module.scss';
import { ArrowIcon } from './icons';
import { Centered } from './utils';

//
// BACK BUTTON
//
export const BackButton = ({ label }) => (
	<div className={styles.backButton}>
		<Link 
			to='/' 
			title={label}
			className={styles.backButtonLink}>
			<ArrowIcon 
				className={styles.backButtonSvg} />
		</Link>
	</div>
);

BackButton.defaultProps = {
	label: 'Go back'
};

BackButton.propTypes = {
	label: PropTypes.string.isRequired
};

//
// NAVIGATION
//
const Navigation = ({ isOpen }) => (
  <StaticQuery
    query={navigationQuery}
    render={
    	(data) => navigationRender({
			isOpen,
			data
		})
    }
  />
);

Navigation.defaultProps = {
	isOpen: false
};

Navigation.propTypes = {
	isOpen: PropTypes.bool.isRequired
};

const navigationRender = ({ data, isOpen }) => {
	const {
		allSitePage: {
			edges
		}
	} = data;

	const items = edges.map((edge, index) => {
		const {
			node: {
				path,
				id: key,
				context: {
					title
				}
			}
		} = edge;

		return (
			<PosedLink 
				to={path} 
				key={key}
				className={styles.navigationItem}
				activeClassName={styles.navigationItemActive}>
				{title}
			</PosedLink>
		);
	});

	return (
		<PosedNavigation 
			className={styles.navigation}
			pose={isOpen ? 'enter' : 'exit'}>
			<Centered>
				{items}
			</Centered>
		</PosedNavigation>
	);
};

const navigationQuery = graphql`
	query navigationQuery {
		allSitePage(
			sort: {context: {order: ASC}}
			filter: {context: {menu: {eq: "main"}}}

		) {
			edges {
				node {
					id,
					path,
					context {
						title
					}
				}
			}
		}
	}
`;

export default Navigation;

//
// Animations
//
const PosedNavigation = posed.div({
	enter: {
		opacity: 1,
		staggerChildren: 50,
		beforeChildren: true,
		applyAtStart: {
			visibility: 'visible'
		}
	},
	exit: {
		opacity: 0,
		staggerChildren: 50,
		staggerDirection: -1,
		afterChildren: true,
		applyAtEnd: {
			visibility: 'hidden'
		}
	}
});

const PosedLink = posed(
	forwardRef(
		(props, ref) =>
			<Link
				innerRef={ref} {...props} />
	)
)({
	enter: { x: '0%', opacity: 1 },
	exit: { x: '50%', opacity: 0 }
});

